/* km 3/9/23: this adds a 10px buffer around all the controls to and captures
     clicks to avoid accidental create tree form openings when attempting to
     zoom/geolocate */
.mapboxgl-ctrl-top-right {
  padding: 10px;
  pointer-events: auto;
}

/* km 3/9/23: needed after adding padding 10px to .mapboxgl-ctrl-top-right to
     get rid of the extra 10px on the right */
.mapboxgl-ctrl-top-right .mapboxgl-ctrl {
  margin: 0 0 10px 0;
}

/* km 3/9/23: this makes the button larger to make it easier to tap on mobile
     and to be compliant with WACG 2.1 2.5.5 Target Size of 44px x 44px */
.mapboxgl-ctrl-group button {
  height: 44px;
  width: 44px;
}

.mapboxgl-ctrl button.mapboxgl-ctrl-zoom-in .mapboxgl-ctrl-icon {
  transform: scale(1.3);
}
